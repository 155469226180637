const md5 = require("md5");
const sha1 = require("sha1");

export default class Application {
    constructor(args) {
        try {
            // Copy everything given to the constructor; storage and api are expected
            // Storage will be sent along so the API can properly initialize
            for (let prop in args) {
                this[prop] = args[prop];
            }

            // Get stuff from config
            this.name = config.VUE_CONFIG_APP_NAME;
            this.version = 'v' + config.VUE_APP_VERSION;
            this.latestVersion = '-';
            this.last_started = this.storage.set( 'app.last_started', this.GMTToLocal().substring(0,16));

            // Get stuff from the local storage
            this.properties = ['user', 'tables.users'];
            for (let prop of this.properties) {
                this[prop] = this.storage.get(prop);
            }

            // When closing a form, we would like to go back to either the list or the module, so remember this: list or module
            this.formOpener = 'list';

            // These are the timer values used to control the timeout before the upload function is called again
            this.uploadTimerFast = 200;
            this.uploadTimerSlow = 10000;
            this.uploadHandle = [];

            // When loading a form to read a record, initForm will pickup the values from this global
            // If null, the form is for editing; if 
            this.record = null;
        } catch (error) {
            console.error(error);
        }
    }

    initialize() {
        console.log('app.initialize()');
        let self = this;

        // Initialization of the API may take some time as it may want to login at the server, so make it a promise
        return new Promise(function (resolve, reject) {
            try {
                self.api.initialize(self.storage);

                // Initialize the console (don't post logs, do post errors)
                if (self.console) {
                    if (window.location.host != 'localhost:8080' && self.api.server) {
                        self.console.initialize(false, true, 'https://' + self.api.server + self.api.server_path + 'console');
                    }
                }

                // Get the latest available version of the app
                self.api.getLatestVersion().then( version => {
                    self.latestVersion = version;
                });

                // Start the uploader
                if (self.storage.get('app.uploadAutomatic') === null) {
                    self.storage.set('app.uploadAutomatic', true);
                }
                self.upload();

                resolve();
            } catch (error) {
                console.error(error);
                reject(error);
            }
        });
    }

    server() {
        return (window.location.href + '#').split('#')[0]
    }

    isMobile() {
        return (/iPhone|iPad|iPod|Android|Opera\sMini|Windows\sPhone/i.test(navigator.userAgent));
    }

    isOnline() {
        let self = this;
        return self.api.isOnline;
    }

    isStandalone() {
        // https://stackoverflow.com/a/51735941/4177565
        return (window.matchMedia('(display-mode: standalone)').matches);
    }

    GMTToLocal(GMTTime = null) {
        var date = GMTTime ? new Date(GMTTime) : new Date();
        return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().substring(0, 19).replace('T', ' ');
    }

    getGPS(callback) {
        console.log('app.getGPS(<callback>)');

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    callback(position);
                },
                (error) => {
                    console.error(error);
                    callback(null);
                }, {
                maximumAge: 0,
                timeout: 5000,
                enableHighAccuracy: true
            }
            );
        } else {
            callback(null);
        }
    }

    generateUUID() {
        // https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid
        if (crypto) {
            return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
                (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
            );
        } else {
            let d = new Date().getTime();
            let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                let r = Math.random() * 16;
                if (d > 0) { // Use timestamp until depleted
                    r = (d + r) % 16 | 0;
                    d = Math.floor(d / 16);
                } else { // Use microseconds since page-load (if supported)
                    r = (d2 + r) % 16 | 0;
                    d2 = Math.floor(d2 / 16);
                }
                return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
            });
        }
    }

    escapeHTML(html) {
        //return String(html).replaceAll('<', '&lt;').replaceAll('>', '&gt;');
        let result = new String(html);
        result = html.replace(new RegExp('<', 'g'), '&lt;');
        result = html.replace(new RegExp('>', 'g'), '&gt;');
        return result;
    }

    parseNumber(value) {
        // Parse value as float, but replace the european comma with a dot first
        if (value.trim() == '') {
            return 0;
        }
        // return parseFloat(value.trim().replaceAll(' ', '').replaceAll(', ', '.'));
        let result = new String(value.trim());
        result = result.replace(new RegExp(' ', 'g'), '');
        result = result.replace(new RegExp(', ', 'g'), '.');
        return parseFloat(result);
    }

    alert(message, title = '') {
        console.log('app.alert(' + message + ',' + title + ')');
        let self = this || window.application;

        // Load the title and text
        document.getElementById('infoModalLabel').innerText = title || self.name;
        document.getElementById('infoModalDescription').innerText = message;

        // Hide the Cancel button, prepare the OK button
        document.getElementById('infoModalCancel').hidden = true;
        document.getElementById('infoModalOK').hidden = false;
        document.getElementById('infoModalOK').removeAttribute('onclick');
        // Show the modal with the overview
        document.getElementById('infoModal')._modal.show();
    }

    confirm(message, title = '', cbOK = null, cbCancel = null) {
        console.log('app.confirm(' + message + ',' + title + ',<cbOK>,<cbCancel>)');
        let self = window.application;

        // Load the title and text
        document.getElementById('infoModalLabel').innerText = title || self.name;
        document.getElementById('infoModalDescription').innerText = message;

        // Note: data-bs-dismiss="modal" on the OK and Cancel buttons would prevent the onclick code below!
        // Show the Cancel and OK buttons and prepare them
        document.getElementById('infoModalCancel').hidden = false;
        document.getElementById('infoModalCancel').onclick = function() {
            // Reset the onclick itself so it won't happen next time
            document.getElementById('infoModalCancel').removeAttribute('onclick');
            // Close the popup
            self.popupInfoClose();
            // Do what needs to be done, if there is anything
            if (cbCancel) { cbCancel() }
        }

        document.getElementById('infoModalOK').hidden = false;
        document.getElementById('infoModalOK').onclick = function() {
            // Reset the onclick itself so it won't happen next time
            document.getElementById('infoModalOK').removeAttribute('onclick');
            // Close the popup
            self.popupInfoClose();
            // Do what needs to be done, if there is anything
            if (cbOK) { cbOK() }
        }

        // Show the modal with the overview
        document.getElementById('infoModal')._modal.show();
    }

    login(server = '', username = '', password = '', forceServer = false) {
        console.log('app.login(' + server + ',' + username + ',<password>,' + forceServer + ')');
        let self = this;

        if (self.storage.has('info.user') && !forceServer && !self.isOnline()) {
            return new Promise(function (resolve, reject) {
                console.log('Logging in locally');
                let users = self.storage.get('info.user');
                let password_hash = sha1(md5(password));
                for (let u in users) {
                    if (users[u].username == username && users[u].password == password_hash) {
                        // The token is set to something not empty, so this will allow the user to use the app locally
                        // But before uploading, the user will have to authenticate
                        self.user = {
                            'id': users[u].id,
                            'token': 'local-login',
                            'username': users[u].username,
                        };
                        self.storage.set('user', self.user);
                        self.storage.set('app.last_login', self.GMTToLocal().substring(0,16));
                        resolve(true);
                    }
                }
                reject( 'The username/password combination was not found.' );
            });
        } else {
            return new Promise(function (resolve, reject) {
                console.log('Logging in remotely');
                self.api.login(server, username, password)
                    .then(result => {
                        self.user = self.storage.set('user', result);
                        self.storage.set('app.last_login', self.GMTToLocal().substring(0,16));
                        resolve(true);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        }
    }

    logout() {
        console.log('app.logout()');
        let self = this;

        try {
            // Remove the token and store the new user object
            delete self.user.token;
            self.user = self.storage.set('user', self.user);
            return true;
        } catch (error) {
            console.error(error);
            return true;
        }
    }

    isModuleLoaded(id = 'container_module') {
        let el = document.getElementById(id);
        return (!el.hidden);
    }

    executeScripts(containerElement) {
        console.log('app.executeScripts(<containerElement>)');

        // This is used by loadHTML; when setting html, script elements are also created but not executed; this does that
        // https://stackoverflow.com/a/69190644/4177565
        return new Promise(function (resolve, reject) {
            try {
                const scriptElements = containerElement.querySelectorAll("script");
                Array.from(scriptElements).forEach((scriptElement) => {
                    const clonedElement = document.createElement("script");
                    Array.from(scriptElement.attributes).forEach((attribute) => {
                        clonedElement.setAttribute(attribute.name, attribute.value);
                    });
                    clonedElement.text = scriptElement.text;
                    scriptElement.parentNode.replaceChild(clonedElement, scriptElement);
                });
                resolve();
            } catch(e) {
                reject( e );
            }
        });
    }

    loadHTML(componentName, querySelect = '#postModal .html') {
        console.log('app.loadHTML(' + componentName +',"' +querySelect +'")');
        let self = this;

        // Close the info modal
        self.popupInfoClose();

        return new Promise(function (resolve, reject) {
            // Get the html
            import('@/components/' +componentName +'.html')
            .then( (componentObj) => {
                try {
                    // Get the element
                    var el = document.querySelector( querySelect );
                    if( el ) {
                        el.innerHTML = componentObj.default;

                        window.application.executeScripts( el )
                        .then( () => {
                            resolve();
                        })
                        .catch( () => {
                            throw new Error( 'app.loadHTML: Error executing scripts for "' +componentName +'".' );
                        });
                    } else {
                        throw new Error( 'app.loadHTML: Error loading component "' +componentName +'": query "' +querySelect +'" did not find an element.' );
                    }
                } catch (error) {
                    console.error(error);
                    reject(error);
                }
            })
            .catch( () => {
                reject( 'app.loadHTML: Component "' +componentName +'" does not exist.' );
            });
        });
    }

    popupInfoClose() {
        console.log('app.popupInfoClose()');

        try {
            document.getElementById('infoModal')._modal.hide();
        } catch (error) {
            console.error(error);
        }
    }

    download(info, progress) {
        console.log('app.download(<info>,<progress>)');
        let self = this;

        // eli = The element for the textual information, e.g. "Downloading 14/47"
        // elp = The progress bar element
        let eli = document.getElementById(info);
        let elp = document.getElementById(progress);

        function update(completed, total) {
            try {
                eli.innerText = 'One moment, synchronizing (' + completed + ' / ' + total + ')...';
                elp.style.width = Math.round(completed / total * 100) + '%';
            } catch (error) {
                console.error(error);
            }
        }

        function progressPromise(promises, tickCallback) {
            function tick(promise) {
                promise.then(function () {
                    progress++;
                    tickCallback(progress, len);
                });
                return promise;
            }

            var len = promises.length;
            var progress = 0;
            return Promise.all(promises.map(tick));
        }

        return new Promise(function (resolve, reject) {
            try {
                eli.innerText = 'One moment, checking what needs to be synchronized...';
                elp.style.width = "0%";

                self.api.checkToken().then(() => {
                    // Do the initial fetches to decide what to download; these initial three fetches let us know what to download
                    let headers = new Headers({
                        'Authorization': 'Bearer ' + self.api.token
                    });
                    Promise.all([
                        fetch('https://' + self.api.server + self.api.server_path + 'info', { headers: headers }),
                        fetch('https://' + self.api.server + self.api.server_path + 'modules', { headers: headers }),
                    ]).then((responses) => {
                        // Make sure we get a JSON object from each of the responses
                        return Promise.all(responses.map(function (response) {
                            return response.json();
                        }));
                    }).then((data) => {
                        // Make a list of tasks to carry out: get the blob keys and then decide what downloads to add to the task list
                        self.storage.blobs().then((idbKeys) => {
                            let tasks = [];
                            for (let key in data) {
                                for (let key2 in data[key].data.list) {
                                    if (data[key].data.type == 'modules') {
                                        if (!idbKeys.includes('modules.' + data[key].data.list[key2])) {
                                            tasks.push(fetch('https://' + self.api.server + self.api.server_path + data[key].data.type + '/' + data[key].data.list[key2], { headers: headers }));
                                        }
                                    } else {
                                        tasks.push(fetch('https://' + self.api.server + self.api.server_path + data[key].data.type + '/' + data[key].data.list[key2], { headers: headers }));
                                    }
                                }
                            }

                            // Loop through the keys in indexedDB and remove those modules that aren't in the download list
                            for (let idbKey in idbKeys) {
                                if (idbKeys[idbKey].indexOf('modules') === 0) {
                                    let found = false;
                                    for (let key in data) {
                                        if (data[key].data.type == 'modules') {
                                            for (let key2 in data[key].data.list) {
                                                if ('modules.' + data[key].data.list[key2] == idbKeys[idbKey]) {
                                                    found = true;
                                                }
                                            }
                                        }
                                    }
                                    if (!found) {
                                        tasks.push(new Promise((resolve) => {
                                            window.application.storage.removeBlob(idbKeys[idbKey])
                                                .then(resolve('Removed blob ' + idbKeys[idbKey] + ' from indexedDB'));
                                        }));
                                    }
                                }
                            }

                            // Now that we have all tasks, carry them out
                            eli.innerText = 'One moment, downloading...';
                            progressPromise(tasks, update).then((responses) => {
                                // Make sure we get a JSON object from each of the responses
                                return Promise.all(responses.map(function (response) {
                                    if (response.headers) {
                                        if (response.headers.get('content-type') == 'application/json')
                                            return response.json().catch(error => {
                                                console.error(error);
                                                return null;
                                            });
                                    }
                                    return response;
                                }));
                            }).then(
                                (results) => {
                                    try {
                                        if (results) {
                                            for (let key in results) {
                                                if (results[key].data) {
                                                    switch (results[key].data.type) {
                                                        case 'info':
                                                            self.storage.set('info.' + results[key].data.name, results[key].data.content);
                                                            break;
                                                        case 'installations_modules':
                                                            self.storage.set('info.' + results[key].data.name, results[key].data.content);
                                                            break;
                                                        case 'form':
                                                            self.storage.set('forms.' + results[key].data.name, results[key].data.content);
                                                            break;
                                                        case 'module':
                                                            self.storage.setBlob('modules.' + results[key].data.name, self.storage.base64_to_blob(results[key].data.content));
                                                            break;
                                                        default:
                                                            // Not accounted for, yet
                                                            console.log('Missing handler for ' + results[key].data.type);
                                                            break;
                                                    }
                                                }
                                            }
                                        }
                                        self.storage.set('sync.downloaded', self.GMTToLocal().substring(0, 16));
                                        eli.innerText = 'Done...';
                                        elp.style.width = "100%";
                                        resolve();
                                    } catch (error) {
                                        console.error(error);
                                        eli.innerText = 'An error occurred. Please try again later...';
                                        elp.style.width = "0%";
                                        reject();
                                    }
                                }
                            );
                        });
                    }).catch((error) => {
                        console.error(error);
                        eli.innerText = 'An error occurred. Please try again later...';
                        elp.style.width = "0%";
                        reject();
                    });
                }).catch((error) => {
                    console.error(error);
                    eli.innerText = 'An error occurred. Please log off and on, and try again...';
                    elp.style.width = "0%";
                    reject();
                });

            } catch (error) {
                console.error(error);
                eli.innerText = 'An error occurred. Please try again later...';
                elp.style.width = "0%";
                reject();
            }
        });
    }

    setPostStatus(status = 'local', key = null) {
        // Sets the status of all posts, usually to 'local' so they can be re-uploaded
        console.log('app.setPostStatus(' + status + ',' + key + ')');
        let self = this;

        let posts = self.storage.getAll('created.');
        for (let post in posts) {
            let update = true;
            if (key && posts[post].id !== key) {
                update = false;
            }
            if (update) {
                posts[post].status = status;
                self.storage.set('created.' + posts[post].type + '.' + posts[post].id, posts[post]);
            }
        }
        return true;
    }

    countElementsToUpload() {
        // Counts the number of elements to upload, both those in localStorage and the photos in indexedDB
        console.log('app.countElementsToUpload()');
        let self = this;
        let result = 0;

        // The info in localStorage
        let keys = self.storage.keys();
        for (let k in keys) {
            if (keys[k].substring(0, 8) == 'created.') {
                let post = self.storage.get(keys[k]);
                if (post.status == 'local') {
                    result++;
                }
            }
        }

        // The photos in indexedDB
        let photos = self.storage.get('app.pictures');
        for (let photo in photos) {
            if (photos[photo].status == 'local') {
                result++;
            }
        }

        return result;
    }

    uploadGetAsPromise(key, storagetype = 'localstorage') {
        // This is a promise that picks up either the content of key in localStorage, or of a blob from indexeddb
        // It is called by upload(); because indexeddb works with promises, upload() needs to do a .then()
        return new Promise(function (resolve, reject) {
            if (storagetype == 'localstorage') {
                resolve(self.storage.get(key));
            } else {
                self.storage.getBlob(key).then((result) => {
                    try {
                        // Convert the result to base64
                        resolve(window.btoa(String.fromCharCode(...new Uint8Array(result))));
                    } catch (error) {
                        reject(error);
                    }
                }).catch((error) => {
                    reject(error);
                });
            }
        });
    }

    upload() {
        // This is run automatically on app start, and it will keep on running
        // For every iteration it sees if it needs to upload elements (in the foreground or background) and does so for 1 element
        // The timeout is controlled by:
        // * this.uploadTimerFast: when the upload button is clicked, or there's stuff to upload, we repeat quickly again
        // * this.uploadTimerSlow: when there is nothing to do, we give the cpu a longer break before running this again
        // Note: window.setTimeOut is erratic when the app is not in focus; that is just fine
        console.log('app.upload()');
        let self = this;

        // Since we are now running inside the upload() function, cancel all uploadHandles
        // We'll be able to call upload() as often as we want, but it will only run once
        while( self.uploadHandle.length ) {
            var timeoutRef = self.uploadHandle.pop();
            window.clearTimeout( timeoutRef );
        }

        // If we're offline, exit rightaway
        if (!self.isOnline()) {
            self.uploadHandle.push( window.setTimeout(() => {
                self.upload();
            }, self.uploadTimerSlow ));
        }

        // We'll find a key of a post that hasn't been uploaded yet, either in localstorage or indexeddb
        let key = null;
        let storagetype = null;

        let uploadAuto = self.storage.get('app.uploadAutomatic');
        let uploadManual = self.storage.get('app.uploadManual');
        if (uploadAuto || uploadManual) {
            // The user has autoUpload on, or is looking at the modal
            // So we will want to actually upload content, if possible and necessary, and then continue asap with the next upload()

            // Get any uploadable element
            let keys = self.storage.keys();
            for (let k in keys) {
                if (keys[k].substring(0, 8) == 'created.') {
                    // Inspect the status
                    let post = self.storage.get(keys[k]);
                    if( (post.status || 'local') == 'local' ) {
                        key = keys[k];
                        storagetype = 'localstorage';
                    }
                }
            }

            // If there are no posts to upload, check for pictures
            // The picture list is kept in localstorage with key "key.pictures"
            if (!key) {
                let pictures = self.storage.get('app.pictures');
                for (let picture in pictures) {
                    if (pictures[picture].status == 'local') {
                        key = pictures[picture].key;
                        storagetype = 'indexeddb';
                    }
                }
            }

            // If there is nothing to upload, set the progress bar as finished
            if (!key) {
                self.storage.set('app.uploadManual', false);
                self.storage.set('sync.uploaded', self.GMTToLocal().substring(0, 16));

                let eli = document.getElementById('upload_inform')
                if (eli) { eli.innerText = 'All informasjon er lastet opp.'; }
                let elp = document.getElementById('upload_progress');
                if (elp) { elp.style.width = '100%'; }
            }
        }

        if (key) {
            // Get the content
            self.uploadGetAsPromise(key, storagetype)
                .then((content) => {
                    // Images are base64, which is a string; posts are jsons which are objects
                    let method = 'post_info';
                    if (typeof content == 'string') {
                        method = 'post_image';
                    }

                    // Do the upload, and if all went well, inform, and update the record
                    self.api.doPost(method, key, content).then((result) => {
                        // If the result was successful
                        if (result) {
                            // Update the status so we know when it was last run
                            self.storage.set('sync.uploaded', self.GMTToLocal().substring(0, 16));
                            self.api.upload_done = self.api.upload_done + 1;

                            // Update the status of the post, or if this is a picture, of the entry in app.pictures
                            console.log('Updating status for ' + key);
                            if (storagetype == 'localstorage') {
                                content.status = 'uploaded';
                                self.storage.set(key, content);
                            } else {
                                let currentValues = self.storage.getFrom('app.pictures', key);
                                self.storage.setIn('app.pictures', key, {
                                    key: key,
                                    createdAt: currentValues.createdAt,
                                    status: 'uploaded'
                                });
                            }

                            // Update the progress bar, if available
                            let elp = document.getElementById('upload_progress');
                            if (elp) {
                                // Adjust the width based on the number elements uploaded vs those initially to be done
                                let width = Math.round(self.api.upload_done / self.api.upload_total * 100);
                                if (width > 100) {
                                    width = 100;
                                }
                                elp.style.width = width + '%';
                            } else {
                                // If the progress bar with modal is not available, let the screen be redrawn
                                window.dispatchEvent(new CustomEvent('storage-changed', {
                                    detail: {
                                        action: 'upload',
                                        key: key
                                    }
                                }));
                            }

                            // Call the function again, shortly
                            self.uploadHandle.push( window.setTimeout(() => {
                                self.upload();
                            }, self.uploadTimerFast ));
                        } else {
                            console.error('An error occurred posting data to the API.', result);
                        }
                    });
                })
                .catch((error) => {
                    console.error(error);
                    if (key.substring(0, 7) == 'photos.') {
                        console.log('Updating status for ' + key + ': it was specified in app.pictures but was not found in indexedDB.');
                        let currentValues = self.storage.getFrom('app.pictures', key);
                        self.storage.setIn('app.pictures', key, {
                            key: key,
                            createdAt: currentValues.createdAt,
                            status: 'uploaded'
                        });
                    }

                    // Call the function again, shortly
                    self.uploadHandle.push( window.setTimeout(() => {
                        self.upload();
                    }, self.uploadTimerFast ));
                });
        } else {
            // This function runs periodically anyways and should continue to do so, but give the cpu a break of a few seconds
            // This is long enough so the CPU isn't bothered too much, yet short enough for the user to wait when clicking upload
            self.uploadHandle.push( window.setTimeout(() => {
                self.upload();
            }, self.uploadTimerSlow ));
        }
    }

    cleanup(days = 90, localStorage = true, indexedDB = true) {
        // Cleans up already-uploaded elements if they are more than <days> days old
        console.log('app.cleanup(' + days + ',' + localStorage + ',' + indexedDB + ')');
        let cutoffDate = this.GMTToLocal(new Date(Date.now() - days * 24 * 60 * 60 * 1000));

        // Loop through the posts in localStorage
        let cntRemovedPosts = 0;
        if (localStorage) {
            let posts = this.storage.getAll('created.');
            for (let post of posts) {
                if (post.status == 'uploaded') {
                    if (post.createdAt < cutoffDate) {
                        this.storage.remove('created.' + post.type + '.' + post.id);
                        cntRemovedPosts++;
                    }
                }
            }
        }

        // Loop through the blobs in indexedDB (pictures)
        // Value app.pictures is set when taking a picture and updated when uploading
        let cntRemovedBlobs = 0;
        let promises = [];
        if (localStorage) {
            let picture_list = self.storage.get('app.pictures');
            for (let el in picture_list) {
                if (picture_list[el].status == 'uploaded') {
                    if (picture_list[el].createdAt < cutoffDate) {
                        promises.push(window.application.storage.removeBlob(picture_list[el].key));
                    }
                }
            }
        }

        // Loop through the blobs in indexedDB (modules)
        // Value app.modules.obsolete is set and updated when downloading modules
        if (localStorage) {
            let module_list = self.storage.get('app.modules.obsolete');
            for (let el in module_list) {
                promises.push(window.application.storage.removeBlob(module_list[el].key));
            }
        }

        let eli = document.getElementById('cleanup_inform');
        if (promises.length == 0) {
            if (cntRemovedPosts == 0) {
                eli.innerText = 'Done.\nThe app did not need to clean up.';
            } else {
                eli.innerText = 'Done.\n' + cntRemovedPosts + ' old posts were cleaned up.';
            }
        } else {
            Promise
                .allSettled(promises)
                .then((result) => {
                    // Remove the pictures from the list (deleting them once is enough)
                    let picture_list = self.storage.get('app.pictures');
                    for (let el in picture_list) {
                        if (picture_list[el].createdAt < cutoffDate) {
                            self.storage.removeFrom('app.pictures', picture_list[el].key);
                        }
                    }

                    // Count the results and report
                    for (let result_el of result) {
                        if (result_el) {
                            cntRemovedBlobs++;
                        }
                    }
                    if (eli) {
                        if (cntRemovedBlobs == promises.length) {
                            if (cntRemovedPosts == 0 && cntRemovedBlobs == 0) {
                                eli.innerText = 'Done.\nThe app did not need to clean up anything.';
                            } else if (cntRemovedPosts == 0 && cntRemovedBlobs > 0) {
                                eli.innerText = 'Done.\nNo posts needed to be cleaned up, and ' + cntRemovedBlobs + ' modules were cleaned up.';
                            } else if (cntRemovedPosts > 0 && cntRemovedBlobs == 0) {
                                eli.innerText = 'Done.\nNo modules needed to be cleaned up, and ' + cntRemovedPosts + ' posts were cleaned up.';
                            } else {
                                eli.innerText = 'Done.\nThe app cleaned up ' + cntRemovedPosts + ' posts and ' + cntRemovedBlobs + ' modules.';
                            }
                        } else {
                            eli.innerText = 'An error occurred.\nContact support please.';
                        }
                    }
                });
        }
    }

    restart() {
        console.log( 'app.restart()' );
        location.href = "/";
    }

    async getCachedUrls( cacheName, cb ) {
        // https://stackoverflow.com/a/61254111/4177565
        console.log( 'app.getCachedUrls()' );
        const urls = (await (await caches.open(cacheName)).keys()).map(i => i.url)
        return (cb) ? cb(urls) : urls 
    }

    getOSAndBrowserInfo() {
        console.log( 'app.getOSAndBrowserInfo()' );
        let userAgent = navigator.userAgent;
        let platform = navigator.platform;
        let os = "Unknown OS";
        let browser = "Unknown Browser";
        let browserVersion = "Unknown Version";

        // Detect OS
        if (/Win/i.test(platform)) os = "Windows";
        else if (/Mac/i.test(platform)) os = "MacOS";
        else if (/Linux/i.test(platform)) os = "Linux";
        else if (/Android/i.test(userAgent)) os = "Android";
        else if (/iPhone|iPad|iPod/i.test(userAgent)) os = "iOS";

        // Detect Browser
        if (/Edg\/(\d+)/i.test(userAgent)) {
            browser = "Edge";
            browserVersion = userAgent.match(/Edg\/(\d+)/i)[1];
        } else if (/Chrome\/(\d+)/i.test(userAgent) && !/Edg/i.test(userAgent)) {
            browser = "Chrome";
            browserVersion = userAgent.match(/Chrome\/(\d+)/i)[1];
        } else if (/Firefox\/(\d+)/i.test(userAgent)) {
            browser = "Firefox";
            browserVersion = userAgent.match(/Firefox\/(\d+)/i)[1];
        } else if (/Safari\/(\d+)/i.test(userAgent) && !/Chrome/i.test(userAgent)) {
            browser = "Safari";
            browserVersion = userAgent.match(/Version\/(\d+)/i) ? userAgent.match(/Version\/(\d+)/i)[1] : "Unknown";
        } else if (/MSIE (\d+)/i.test(userAgent) || /Trident\/.*rv:(\d+)/i.test(userAgent)) {
            browser = "Internet Explorer";
            browserVersion = userAgent.match(/MSIE (\d+)/i) ? userAgent.match(/MSIE (\d+)/i)[1] : userAgent.match(/rv:(\d+)/i)[1];
        }

        return { os, browser, browserVersion };
    }

    forceUpdateAndRestart() {
        console.log( 'app.forceUpdateAndRestart()' );
        navigator.serviceWorker.getRegistration().then(function (reg) {
            if (reg) {
                reg.update().then(function () {
                    window.location = '/';
                });
            }
        });
    }
}